import React from "react";
import { Row, Col } from "reactstrap";
import { Field, FormikProps, Form } from "formik";

import StrainersTest from "./components/StrainersTest";
import {
  InputColumn,
  InputSelect,
  InputInteger,
} from "../../../../../components/Inputs";
import Chart from "./Chart";
import { BANDS } from "./strainers";
import ExecutionBar from "../components/ExecutionBar";

const BGSForm: React.FC<FormikProps<Granulometry>> = ({
  values,
  isSubmitting,
  setFieldValue,
}) => {
  const onChangeBand = (value: string) => {
    const strainers = BANDS[value].map((newItem) => {
      const currentItem = values.strainers.filter(
        (currentItem) => newItem.gap === currentItem.strainer.gap,
      )[0];

      if (currentItem !== undefined) {
        return {
          id: currentItem.id,
          strainer: {
            id: currentItem.strainer.id,
            withheld_weight: currentItem.strainer.withheld_weight,
            gap: newItem.gap,
            project: undefined,
            inches: newItem.inches,
          },
          is_bgs: true,
        };
      }

      return {
        id: undefined,
        strainer: {
          id: undefined,
          withheld_weight: undefined,
          gap: newItem.gap,
          project: undefined,
          inches: newItem.inches,
        },
        is_bgs: true,
      };
    });
    setFieldValue("strainers", strainers);
  };

  return (
    <Form>
      <ExecutionBar isSubmitting={isSubmitting} />
      <Row className="mb-2">
        <Col sm="2">
          <InputColumn label="Peso total">
            <Field name="total_weight" component={InputInteger} />
          </InputColumn>
        </Col>
        <Col sm="2">
          <InputColumn label="Faixa">
            <Field
              name="band"
              component={InputSelect}
              isClearable={false}
              options={[
                { value: "A", label: "A" },
                { value: "B", label: "B" },
                { value: "C", label: "C" },
                { value: "D", label: "D" },
                { value: "E", label: "E" },
                { value: "F", label: "F" },
              ]}
              onClick={onChangeBand}
            />
          </InputColumn>
        </Col>
      </Row>
      <Row>
        <Col sm={7}>
          <StrainersTest
            name="strainers"
            totalWeight={values.total_weight}
            values={values.strainers}
            setFieldValue={setFieldValue}
            bands={BANDS[values.band || ""]}
          />
        </Col>
        <Col sm={5}>
          <Row>
            <Chart bands={BANDS[values.band || ""]} />
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default BGSForm;
