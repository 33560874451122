const sievesInches = [
  "2",
  "1 1/2",
  "1",
  "3/4",
  "3/8",
  "N.4",
  "N.10",
  "N.16",
  "N.30",
  "N.40",
  "N.50",
  "N.100",
  "N.200",
];

const strainers = [
  { inches: "2", gap: 50.8 },
  { inches: "1 1/2", gap: 38.1 },
  { inches: "1", gap: 25.4 },
  { inches: "3/4", gap: 19.1 },
  { inches: "3/8", gap: 9.5 },
  { inches: "N.4", gap: 4.8 },
  { inches: "N.10", gap: 2.0 },
  { inches: "N.16", gap: 1.2 },
  { inches: "N.30", gap: 0.6 },
  { inches: "N.40", gap: 0.42 },
  { inches: "N.50", gap: 0.3 },
  { inches: "N.100", gap: 0.15 },
  { inches: "N.200", gap: 0.074 },
];

const BANDS: {
  [key: string]: { gap: number; inches: string; percentage: number[] }[];
} = {
  A: [
    { gap: 50.8, inches: "2", percentage: [1, 1, 0.07, 0.07] },
    { gap: 9.5, inches: "3/8", percentage: [0.3, 0.65, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.25, 0.55, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.15, 0.44, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.06, 0.2, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.02, 0.08, 0.02, 0.02] },
  ],
  B: [
    { gap: 50.8, inches: "2", percentage: [1, 1, 0.07, 0.07] },
    { gap: 25.4, inches: "1", percentage: [0.75, 0.9, 0.07, 0.07] },
    { gap: 9.5, inches: "3/8", percentage: [0.4, 0.75, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.3, 0.6, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.2, 0.45, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.15, 0.3, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.05, 0.15, 0.02, 0.02] },
  ],
  C: [
    { gap: 25.4, inches: "1", percentage: [1, 1, 0.07, 0.07] },
    { gap: 9.5, inches: "3/8", percentage: [0.5, 0.85, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.35, 0.65, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.25, 0.55, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.15, 0.3, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.05, 0.15, 0.02, 0.02] },
  ],
  D: [
    { gap: 25.4, inches: "1", percentage: [1, 1, 0.07, 0.07] },
    { gap: 9.5, inches: "3/8", percentage: [0.6, 1, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.5, 0.85, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.4, 0.7, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.25, 0.45, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.1, 0.25, 0.02, 0.02] },
  ],
  E: [
    { gap: 25.4, inches: "1", percentage: [1, 1, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.55, 1, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.4, 1, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.2, 0.5, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.06, 0.2, 0.02, 0.02] },
  ],
  F: [
    { gap: 25.4, inches: "1", percentage: [1, 1, 0.07, 0.07] },
    { gap: 4.8, inches: "N.4", percentage: [0.7, 1, 0.05, 0.05] },
    { gap: 2, inches: "N.10", percentage: [0.55, 1, 0.05, 0.05] },
    { gap: 0.42, inches: "N.40", percentage: [0.3, 0.7, 0.02, 0.02] },
    { gap: 0.074, inches: "N.200", percentage: [0.08, 0.25, 0.02, 0.02] },
  ],
};

export { sievesInches, strainers, BANDS };
