import React from "react";
import { Row, Col } from "reactstrap";
import { FormikProps, Form, Field } from "formik";
import ExecutionBar from "../components/ExecutionBar";
import CapsuleStudy, {
  CapsuleStudyLabels,
  CapsuleTotal,
  CapsulePartial,
  CapsuleTotalLabels,
} from "../components/CapsuleStudy";
import StrainerStudy, { StrainerTitle } from "./components/StainerStudy";
import { HeaderTitle } from "../../../../../components/Headers";
import { InputRow, InputSelect } from "../../../../../components/Inputs";
import { toLocateNumber } from "../../../../../utils/format";
import { useGranulometry, useGranulometryActions } from "./context";
import Chart from "./Chart";
import ChartBGS from "./ChartBGS";
import { BANDS } from "./strainers";

const AvgHumidity = () => {
  const [granulometry] = useGranulometry();

  return (
    <Row>
      <Col sm={5}>
        <InputRow label="Umidade média (%)" />
      </Col>
      <Col>
        <InputRow
          className="text-center"
          label={toLocateNumber(granulometry.avgHumidity * 100, 2, 0)}
        />
      </Col>
    </Row>
  );
};

const GranulometryForm: React.FC<FormikProps<Granulometry>> = (props) => {
  const [, actionGranulometry] = useGranulometryActions();

  const renderCapsuleStudy = (index: number) => (
    <Col>
      <CapsuleStudy
        errors={props.errors}
        name={`capsule_studies[${index}]`}
        setFieldValue={props.setFieldValue}
        onHumidityChange={(value: number) =>
          actionGranulometry.setHumidity(value, index)
        }
        values={props.values.capsule_studies[index]}
      />
    </Col>
  );

  return (
    <Form>
      <ExecutionBar isSubmitting={props.isSubmitting}>
        <InputRow label_col={"auto"} label="Faixa">
          <Field
            name="band"
            component={InputSelect}
            options={[
              { value: "", label: "Normal" },
              { value: "A", label: "A" },
              { value: "B", label: "B" },
              { value: "C", label: "C" },
              { value: "D", label: "D" },
              { value: "E", label: "E" },
              { value: "F", label: "F" },
            ]}
          />
        </InputRow>
      </ExecutionBar>
      <Row>
        <Col sm={8}>
          <Row>
            <Col sm={6}>
              <HeaderTitle title="UMIDADE" className="text-center" />
              <Row>
                <Col sm={5}>
                  <CapsuleStudyLabels />
                </Col>
                {renderCapsuleStudy(0)}
                {renderCapsuleStudy(1)}
              </Row>
              <AvgHumidity />
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={5}>
                  <CapsuleTotalLabels />
                </Col>
                <Col>
                  <HeaderTitle title="TOTAL" className="text-center" />
                  <CapsuleTotal
                    name=""
                    setFieldValue={props.setFieldValue}
                    errors={props.errors}
                  />
                </Col>
                <Col>
                  <HeaderTitle title="PARCIAL" className="text-center" />
                  <CapsulePartial
                    name=""
                    setFieldValue={props.setFieldValue}
                    errors={props.errors}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {props.values.band === "" ? (
                <Chart />
              ) : (
                <ChartBGS bands={BANDS[props.values.band || "A"]} />
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={4}>
          <Row>
            <Col>
              <StrainerTitle />
              <StrainerStudy
                setFieldValue={props.setFieldValue}
                name="strainers"
                values={props.values.strainers}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(
  GranulometryForm,
  (props, nextProps) => JSON.stringify(props) === JSON.stringify(nextProps),
);
